import React, { useEffect, useState } from 'react';
import { FansFundMeCheckoutScreen } from '@fans-fund-me/storybook';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import useAuth from '../../contexts/UseAuth';
import axios from 'axios';
import currencies from '../../utilities/currencies.json';
import { StripeExpressCheckoutElementOptions } from '@stripe/stripe-js';
import { redirect } from 'react-router-dom';

export interface CheckoutProps {
  /**
   * The ID of the single payment
   */
  singlePaymentGiftId: string;

  /**
   * The item being purchased
   */
  item: {
    id: string;
    name: string;
    image: string;
    price: string;
    allowRepeatPurchases: boolean;
  };

  /**
   * The service charge
   */
  serviceCharge: string;

  /**
   * The currency conversion charge
   */
  currencyConversionFee: string;

  /**
   * The total cost
   */
  total: string;

  /**
   * The cost of the item
   */
  itemPrice: string;

  /**
   * The currency
   */
  currency: string;

  /**
   * The name of the creator who has listed the item
   */
  creatorName: string;

  /**
   * The profile picture of the creator who has listed the item
   */
  creatorProfilePicture: string;
}

export const Checkout = ({
  item,
  serviceCharge,
  currencyConversionFee,
  total,
  itemPrice,
  currency,
  creatorName,
  creatorProfilePicture,
  singlePaymentGiftId,
}: CheckoutProps) => {
  const auth = useAuth();

  const [giftAnonymously, setGiftAnonymously] = useState(auth.token === null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          process.env.REACT_APP_ENVIRONMENT === 'local'
            ? `http://localhost:3000/complete/`
            : process.env.REACT_APP_ENVIRONMENT === 'dev'
            ? `https://dev.fansfund.me/complete/`
            : `https://fansfund.me/complete/`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setIsLoadingPayment(false);
      setErrorMessage(error.message);

      if (error.payment_intent?.status === 'succeeded') {
        redirect('/payment-complete');
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const ffmSubmit = (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setIsLoadingPayment(true);

    const axiosConfig =
      auth.token === null
        ? {}
        : { headers: { Authorization: `Bearer ${auth.token}` } };

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/singlepayment/${singlePaymentGiftId}`,
        {
          acceptedTermsAndConditions: agreeTerms,
          email: email,
          message: message,
          sendAnonymously: giftAnonymously,
        },
        axiosConfig
      )
      .then(() => {
        setErrorMessage('');
        // setIsLoadingPayment(false);
        handleSubmit(event);
        // document
        //   .getElementById('payment-button')
        //   ?.setAttribute('class', 'button is-loading');
      })
      .catch((error) => {
        setIsLoadingPayment(false);
        setErrorMessage(error.response.data.fields[0]?.message);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    const loadStripeClient = () => {
      setIsLoading(false);

      if (stripe !== null && elements !== null) {
        const expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
          buttonHeight: 43,
          buttonTheme: {
            applePay: 'white',
            googlePay: 'white',
            // paypal: 'black',
          },
          // buttonType: {
          //   applePay: 'buy',
          // },
        };
        const paymentElement = elements.create('payment');
        const expressCheckoutElement = elements.create(
          'expressCheckout',
          expressCheckoutOptions
        );
        // const linkAuthenticationElement = elements.create(
        //   'linkAuthentication',
        //   {
        //     defaultValues: { email: 'foo@bar.com' },
        //   }
        // );

        paymentElement.mount('#payment-element');
        expressCheckoutElement.mount('#express-checkout-element');
        // linkAuthenticationElement.mount('#link-authentication-element');
      }
    };

    loadStripeClient();
  }, [stripe, elements]);

  return (
    <FansFundMeCheckoutScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      giftAnonymously={giftAnonymously}
      setGiftAnonymously={setGiftAnonymously}
      itemImage={item.image}
      itemName={item.name}
      itemPrice={itemPrice}
      serviceFee={serviceCharge}
      conversionFee={currencyConversionFee}
      total={total}
      creatorName={creatorName}
      creatorProfilePicture={creatorProfilePicture}
      email={email}
      setEmail={setEmail}
      message={message}
      setMessage={setMessage}
      userCurrency={currency}
      errorMessage={errorMessage}
      agreeTerms={agreeTerms}
      setAgreeTerms={setAgreeTerms}
      handleSubmit={ffmSubmit}
      paymentIsLoading={isLoadingPayment}
      isLoading={isLoading}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
